import React from 'react';
import { makeStyles } from '@material-ui/core';
import LogoIcon from '../../content/assets/bear.svg';

const useStyles = makeStyles(() => ({
  logo: {
    width: 50,
    height: 50,
  },
}));

const MainLogo = () => {
  const classes = useStyles();
  return <LogoIcon className={classes.logo} />;
};

export default MainLogo;
