import styled from 'styled-components';
import BannerBG from '../images/banner-bg.jpg';

export const BannerWrapper = (image: any | undefined | null) => styled.div`
  position: relative;
  padding: 90px 0;
  background-color: #ffffff;
  background-image: url(${image || BannerBG});
  background-size: cover;
  background-position: center;
  min-height: 870px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1500px) {
    min-height: 700px;
    padding: 80px 0;
  }

  @media (max-width: 990px) {
    min-height: 600px;
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    min-height: 300px;
    padding: 40px 0;
  }
`;
