import { Avatar, makeStyles } from '@material-ui/core';
import React from 'react';
import MainLogo from './components/MainLogo';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.light,
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
}));

const MainLogoAvatar = () => {
  const classes = useStyles();
  return (
    <Avatar className={classes.avatar}>
      <MainLogo />
    </Avatar>
  );
};

export default MainLogoAvatar;
