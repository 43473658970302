import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { CheckTwoTone as CheckIcon } from '@material-ui/icons';
import colors from '../theme/colors';

const useStyles = makeStyles((theme) => ({
  packageContentLineItem: {
    color: colors.primary,
  },
  subItemList: {
    marginLeft: theme.spacing(3),
  },
}));

type CheckedLineItemProps = { lineItemText: string; subItems?: string[] };
const CheckedLineItem = ({ lineItemText, subItems }: CheckedLineItemProps) => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <CheckIcon className={classes.packageContentLineItem} />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1">{lineItemText}</Typography>
          {subItems && subItems.length > 0 && (
            <ul className={classes.subItemList}>
              {subItems.map((subItem) => (
                <li key={subItem}>
                  <Typography variant="body1">{subItem}</Typography>
                </li>
              ))}
            </ul>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CheckedLineItem;
